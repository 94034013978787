import { useCallback } from "react";

import firebase from "firebase/app";

import { useBlockingProgress } from "../components/blocking-progress";
import { useSnackbar } from "../components/snackbar";
import { Task } from "../registration/registration.model";
import { Guide, GuideMode } from "../gardener/gardener.model";

export interface BackendMessage {
    type: "backend message",
    severity: "error" | "warning" | "success" | "info";
    autoHideDuration: number | null;
    message: string;
}

export type TaskResponseData =
    | CreateAppointmentData
    | CreateAdditionalAppointmentData
    | UpdateAppointmentData
    | CancelAppointmentData
    | RejectTaskBecauseGardenerCouldNotFindDateWithCustomerData
    | RejectTaskBecauseGardenerSaysCustomerDoesNotNeedHelpData
    | RejectTaskBecauseGardenerSaysCustomerMightNeedHelpData
    | RejectTaskBecauseGardenerCouldNotHelpCustomerData
    | RegisterContactAttemptData
    | PostponeContactData
    | CreateRegistrationData
    | AskForHelpData
    | MarkGuideCompletedData
    | UpdateIndividualSellerData | UpdateEntitySellerData
    | UpdateContactInformationData

export interface CreateAppointmentData {
    action: "create appointment";
    taskId: string;
    date: string;
    description: string;
}

export interface CreateAdditionalAppointmentData {
    action: "create additional appointment";
    customerId: string;
    date: string;
    description: string;
}

export interface UpdateAppointmentData {
    action: "update appointment";
    taskId: string;
    hasVisited: boolean;
    date: string;
    description: string;
}

export interface CancelAppointmentData {
    action: "cancel appointment";
    taskId: string;
    hasVisited: boolean;
}

export interface RejectTaskBecauseGardenerCouldNotFindDateWithCustomerData {
    action: "reject task because gardener could not find date with customer",
    taskId: string;
    reason: string,
}

export interface RejectTaskBecauseGardenerSaysCustomerDoesNotNeedHelpData {
    action: "reject task because gardener says customer does not need help",
    taskId: string;
    reason: string,
}

export interface RejectTaskBecauseGardenerSaysCustomerMightNeedHelpData {
    action: "reject task because gardener says customer might need help",
    taskId: string;
    reason: string,
}

export interface RejectTaskBecauseGardenerCouldNotHelpCustomerData {
    action: "reject task because gardener could not help customer",
    taskId: string;
    reason: string,
}

export interface RegisterContactAttemptData {
    action: "register contact attempt",
    taskId: string;
    shouldGiveUp: boolean;
}

export interface PostponeContactData {
    action: "postpone contact",
    taskId: string;
    reason: string,
    date: string,
}

export interface CreateRegistrationData {
    action: "create registration",
    taskId: string;
    tasks: Task[],
}

export interface AskForHelpData {
    action: "ask for help";
    message: string;
}

export interface MarkGuideCompletedData {
    action: "mark guide completed";
    mode: GuideMode,
    guide: Guide;
}

export interface UpdateIndividualSellerData {
    action: "update individual seller";
    name: string;
    nameChangeReason: string | null;
    externalAddressId: string;
    cpr: string;
    cprChangeReason: string | null;
    cvr: string | null;
}

export interface UpdateEntitySellerData {
    action: "update entity seller";
    externalAddressId: string;
}

export interface UpdateContactInformationData {
    action: "update contact information";
    email: string;
    phoneNumber: string;
}

const taskResponse: (data: TaskResponseData) => Promise<firebase.functions.HttpsCallableResult>
    = firebase.app().functions("europe-west3").httpsCallable("Match-taskResponse2");

export const useTaskResponseAction = (callback?: (action: TaskResponseData["action"]) => void) => {
    const { block, unblock } = useBlockingProgress();
    const { openSnackbar } = useSnackbar();

    return useCallback((data: TaskResponseData | string) => {
        if ( typeof data === "string" ) return;

        block();

        taskResponse(data)
            .then((response) => {
                if ( response.data && response.data.type === "backend message" ) {
                    const data = response.data as BackendMessage;
                    openSnackbar(data.severity, data.message, data.autoHideDuration);
                    unblock();
                    return;
                }

                if ( response.data === "has-already-scheduled-appointment" ) {
                    openSnackbar("warning", "Du har allerede aftalt et besøg med kunden på denne dato");
                    unblock();
                    return;
                }

                openSnackbar("success", "Besvarelsen er modtaget");
                unblock();
                if ( callback ) callback(data.action);
            })
            .catch(() => {
                openSnackbar("error", "Der skete en fejl. Besvarelsen er ikke blevet modtaget.");
                unblock();
            });

    }, [block, unblock, openSnackbar, callback]);
};
