import { IconButton, makeStyles } from "@material-ui/core";
import EditIcon from "@material-ui/icons/EditOutlined";

import { CardContent } from "../components/card/CardContent";
import { GardenerDocument } from "./gardener.model";
import { Card, CardHeader, CardStatus } from "../components/card";
import { TaskResponseData } from "../task/task-response-action.hook";
import { UpdateGardenerFormIds, useUpdateGardenerDialog } from "./UpdateGardenerDialog";
import { useCallback, useMemo } from "react";
import { FormProps } from "../components/form-item/framework/react/FormProvider";
import { FormItem } from "../components/form-item/framework/core/form-item.model";
import { AddressPickerFormItem } from "../components/form-item/client/form-item/AddressPickerFormItemComponent";
import { Company } from "../company/company.model";
import { PersonalInformationDocument } from "./personal-information.model";
import { formatPaysVat, formatWorkingAs, WorkingAs } from "../company/utility";
import { Notice } from "../components/notice/Notice";
import { SingleChoiceFormItem, TextAreaFormItem } from "../components/form-item/client/components";
import { UpdateGardenerReasonsPageFormItem } from "../components/form-item/client/form-item/UpdateGardenerReasonsPage";

const useStyles = makeStyles({
    group: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "24px",

        "&:last-child": {
            marginBottom: 0,
        },

        "& > label": {
            fontSize: "14px",
            fontWeight: 500,
            color: "#1C5B13",
            marginBottom: "4px",
        },

        "& > span": {
            fontSize: "16px",
            fontWeight: 400,
            color: "#4A4A4A",
        }
    },
    editIconActive: {
        fontSize: "24px",
        color: "#297A1D",
    },
    editIconDisabled: {
        fontSize: "24px",
        color: "#818181",
    },
});

interface GardenerCardProps {
    gardener: GardenerDocument;
    company: Company | null;
    personalInformation: PersonalInformationDocument | null;
    taskResponseAction: (data: TaskResponseData | string) => void;
}

export const GardenerCard = (props: GardenerCardProps) => {
    const classes = useStyles();

    const { gardener, company, personalInformation, taskResponseAction } = props;

    const updateGardenerResponseAdapter = useCallback<FormProps<FormItem>["onSubmit"]>((controller, form): void => {
        const selectedChoiceIndex = (controller.getItem(UpdateGardenerFormIds.SingleChoiceWorkingAs, form) as SingleChoiceFormItem<any>).selectedChoiceIndex!;
        const answerTexts = (controller.getItem(UpdateGardenerFormIds.SingleChoiceWorkingAs, form) as SingleChoiceFormItem<any>).answerTexts;
        const workingAs = answerTexts[selectedChoiceIndex] as WorkingAs;

        if ( workingAs === "Privatperson" ) {
            const reasonsPage = (controller.getItem(UpdateGardenerFormIds.PageReasons, form) as UpdateGardenerReasonsPageFormItem);

            const name = (controller.getItem(UpdateGardenerFormIds.TextAreaName, form) as TextAreaFormItem).value.trim();
            const nameChangeReason = (controller.getItem(UpdateGardenerFormIds.TextAreaNameChangeReason, form) as TextAreaFormItem).value.trim();
            const externalAddressId = (controller.getItem(UpdateGardenerFormIds.AddressPickerAddress, form) as AddressPickerFormItem).value!.externalId;
            const cpr = (controller.getItem(UpdateGardenerFormIds.TextAreaCpr, form) as TextAreaFormItem).value;
            const cprChangeReason = (controller.getItem(UpdateGardenerFormIds.TextAreaCprChangeReason, form) as TextAreaFormItem).value.trim();

            return taskResponseAction({
                action: "update individual seller",
                name,
                nameChangeReason: name.toLowerCase() !== reasonsPage.initialName.toLowerCase() ? nameChangeReason : null,
                externalAddressId,
                cpr,
                cprChangeReason: reasonsPage.initialCpr && cpr !== reasonsPage.initialCpr ? cprChangeReason : null,
                cvr: null,
            });
        }

        if ( workingAs === "Personligt ejet mindre virksomhed" || workingAs === "Enkeltmandsvirksomhed" ) {
            const reasonsPage = (controller.getItem(UpdateGardenerFormIds.PageReasons, form) as UpdateGardenerReasonsPageFormItem);

            const name = (controller.getItem(UpdateGardenerFormIds.TextAreaName, form) as TextAreaFormItem).value.trim();
            const nameChangeReason = (controller.getItem(UpdateGardenerFormIds.TextAreaNameChangeReason, form) as TextAreaFormItem).value.trim();
            const externalAddressId = (controller.getItem(UpdateGardenerFormIds.AddressPickerAddress, form) as AddressPickerFormItem).value!.externalId;
            const cpr = (controller.getItem(UpdateGardenerFormIds.TextAreaCpr, form) as TextAreaFormItem).value;
            const cprChangeReason = (controller.getItem(UpdateGardenerFormIds.TextAreaCprChangeReason, form) as TextAreaFormItem).value.trim();
            const cvr = (controller.getItem(UpdateGardenerFormIds.TextAreaCvr, form) as TextAreaFormItem).value || null;

            return taskResponseAction({
                action: "update individual seller",
                name,
                nameChangeReason: name.toLowerCase() !== reasonsPage.initialName.toLowerCase() ? nameChangeReason : null,
                externalAddressId,
                cpr,
                cprChangeReason: reasonsPage.initialCpr && cpr !== reasonsPage.initialCpr ? cprChangeReason : null,
                cvr,
            });
        }

        if ( workingAs === "Anden juridisk enhed" ) {
            const externalAddressId = (controller.getItem(UpdateGardenerFormIds.AddressPickerAddress, form) as AddressPickerFormItem).value!.externalId;

            return taskResponseAction({
                action: "update entity seller",
                externalAddressId,
            });
        }
    }, [taskResponseAction]);
    const openUpdateGardenerDialog = useUpdateGardenerDialog(gardener, company, personalInformation, updateGardenerResponseAdapter);

    const hasBeenOnboarded = gardener.status === "active" || gardener.status === "terminated";
    const allowEdit = hasBeenOnboarded && !gardener.dac7.reviewActive;
    const editButton = (
        <IconButton aria-label="edit" disabled={!allowEdit} onClick={openUpdateGardenerDialog}>
            <EditIcon className={!allowEdit ? classes.editIconDisabled : classes.editIconActive} />
        </IconButton>
    );

    const paysVat = useMemo(() => formatPaysVat(gardener), [gardener]);
    const workingAs = useMemo(() => formatWorkingAs(company), [company]);

    return (
        <Card>

            {gardener.dac7.requestActive && !gardener.dac7.reviewActive ? <CardStatus color="warning" status="Indsend dine stamdata igen ved at rette dem" /> : null}

            <CardHeader title="Stamdata" button={editButton} />

            <CardContent>

                <div className={classes.group}>
                    <label>Arbejder som</label>
                    <span>{workingAs} ({paysVat})</span>
                </div>

                {workingAs !== "Anden juridisk enhed" ? (
                    <div className={classes.group}>
                        <label>Navn</label>
                        <span>{gardener.name}</span>
                    </div>
                ) : (
                    <div className={classes.group}>
                        <label>Kontaktperson</label>
                        <span>{gardener.name}</span>
                    </div>
                )}

                <div className={classes.group}>
                    <label>Adresse</label>
                    <span>
                        {gardener.address.street}<br />
                        {gardener.address.zipCode}
                    </span>
                </div>

                {workingAs !== "Anden juridisk enhed" ? (
                    <div className={classes.group}>
                        <label>CPR-nummer</label>
                        <span>{personalInformation?.cpr ?? "Ikke angivet"}</span>
                    </div>
                ) : null}

                {workingAs !== "Privatperson" ? (
                    <div className={classes.group}>
                        <label>CVR-nummer</label>
                        <span>{gardener.cvr}</span>
                    </div>
                ) : null}

                {!allowEdit && !hasBeenOnboarded ? <Notice>Du kan ikke rette dine stamdata, før vi har godkendt din ansøgning.</Notice> : null}
                {!allowEdit && hasBeenOnboarded ? <Notice>Vi har modtaget nye oplysninger fra dig. Du kan ikke rette dine stamdata, før vi har gennemgået din rettelse.</Notice> : null}

            </CardContent>
        </Card>
    );
};
