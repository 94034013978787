import { useMemo } from "react";

import { FormProps } from "../components/form-item/framework/react/FormProvider";
import { GroupFormItem, NoticeFormItem, PageFormItem, TextAreaFormItem, useFormDialog } from "../components/form-item/client/components";
import { GardenerDocument } from "./gardener.model";
import { AddressPickerFormItem } from "../components/form-item/client/form-item/AddressPickerFormItemComponent";

export enum UpdateContactInformationFormIds {
    TextAreaPhoneNumber = "TextAreaPhoneNumber",
    TextAreaEmail = "TextAreaEmail",
}

export type UpdateContactInformationForm = PageFormItem<
    GroupFormItem<Array<NoticeFormItem | AddressPickerFormItem | TextAreaFormItem>>
>;

export const useUpdateContactInformationDialog = (gardener: GardenerDocument, onConfirm: FormProps<UpdateContactInformationForm>["onSubmit"]) => {
    const form: UpdateContactInformationForm = useMemo(() => {

        const items: Array<TextAreaFormItem> = [];

        items.push({
            id: UpdateContactInformationFormIds.TextAreaEmail,
            type: "text-area",
            render: "text-field",
            label: "Email",
            placeholder: null,
            value: gardener.email,
            dirty: false,
            validation: "email",
        });

        items.push({
            id: UpdateContactInformationFormIds.TextAreaPhoneNumber,
            type: "text-area",
            render: "text-field",
            label: "Telefon",
            placeholder: null,
            value: gardener.phoneNumber,
            dirty: false,
            validation: "phone",
        });
        
        return {
            type: "page",
            title: "Ret kontaktoplysninger",
            item: {
                type: "group",
                render: "all at once",
                items,
            },
        };
    }, [gardener]);

    return useFormDialog(form, onConfirm);
};
