import { FormProps } from "../components/form-item/framework/react/FormProvider";
import { GroupFormItem, InfoFormItem, NoticeFormItem, PageFormItem, SingleChoiceFormItem, TextAreaFormItem, useFormDialog } from "../components/form-item/client/components";
import { GardenerDocument } from "./gardener.model";
import { AddressPickerFormItem } from "../components/form-item/client/form-item/AddressPickerFormItemComponent";
import { Company } from "../company/company.model";
import { formatWorkingAs, WorkingAs } from "../company/utility";
import { PersonalInformationDocument } from "./personal-information.model";
import { UpdateGardenerReasonsPageFormItem } from "../components/form-item/client/form-item/UpdateGardenerReasonsPage";

export enum UpdateGardenerFormIds {
    SingleChoiceWorkingAs = "SingleChoiceWorkingAs",
    TextAreaName = "TextAreaName",
    AddressPickerAddress = "AddressPickerAddress",
    TextAreaCpr = "TextAreaCpr",
    TextAreaCvr = "TextAreaCvr",
    PageReasons = "PageReasons",
    TextAreaNameChangeReason = "TextAreaNameChangeReason",
    TextAreaCprChangeReason = "TextAreaCprChangeReason",
}

export type UpdateGardenerForm =
    PageFormItem<
        SingleChoiceFormItem<[PrivatpersonGroup, PersonligtEjetMindreVirksomhedGroup, EnkeltmandsvirksomhedGroup]> | SingleChoiceFormItem<[AndenJuridiskEnhedGroup]>
    >;

type PrivatpersonGroup = GroupFormItem<any[]>;
type PersonligtEjetMindreVirksomhedGroup = GroupFormItem<any[]>;
type EnkeltmandsvirksomhedGroup = GroupFormItem<any[]>;
type AndenJuridiskEnhedGroup = GroupFormItem<any[]>;

type ConfirmationPage = PageFormItem<InfoFormItem>;

export const useUpdateGardenerDialog = (gardener: GardenerDocument, company: Company | null, personalInformation: PersonalInformationDocument | null, onConfirm: FormProps<UpdateGardenerForm>["onSubmit"]) => {
    const form = updateGardenerForm(gardener, company, personalInformation);

    return useFormDialog(form, onConfirm);
};

function updateGardenerForm(gardener: GardenerDocument, company: Company | null, personalInformation: PersonalInformationDocument | null): UpdateGardenerForm {
    const workingAs = workingAsSingleChoice(gardener, company, personalInformation);

    return {
        type: "page",
        title: "Ret stamdata",
        item: workingAs,
    };
}

export const individualSellerWorkingAsChoices: WorkingAs[] = ["Privatperson", "Personligt ejet mindre virksomhed", "Enkeltmandsvirksomhed"];
export const entitySellerWorkingAsChoices: WorkingAs[] = ["Anden juridisk enhed"];

function workingAsSingleChoice(gardener: GardenerDocument, company: Company | null, personalInformation: PersonalInformationDocument | null): SingleChoiceFormItem<[PrivatpersonGroup, PersonligtEjetMindreVirksomhedGroup, EnkeltmandsvirksomhedGroup]> | SingleChoiceFormItem<[AndenJuridiskEnhedGroup]> {
    const workingAs = formatWorkingAs(company);

    if ( workingAs === "Anden juridisk enhed" ) {
        return {
            id: UpdateGardenerFormIds.SingleChoiceWorkingAs,
            type: "single-choice",
            mode: "regular",
            render: "dropdown",
            questionText: "Arbejder som",
            answerTexts: entitySellerWorkingAsChoices,
            answerItems: [
                andenJuridiskEnhedGroup(gardener, company),
            ],
            selectedChoiceIndex: entitySellerWorkingAsChoices.indexOf(workingAs),
            dirty: false,
            placeholder: null,
            disabled: true,
        };
    }

    return {
        id: UpdateGardenerFormIds.SingleChoiceWorkingAs,
        type: "single-choice",
        mode: "regular",
        render: "dropdown",
        questionText: "Arbejder som",
        answerTexts: individualSellerWorkingAsChoices,
        answerItems: [
            privatPersonGroup(gardener, company, personalInformation),
            personligtEjetMindreVirksomhedGroup(gardener, company, personalInformation),
            enkeltmandsvirksomhedGroup(gardener, company, personalInformation),
        ],
        selectedChoiceIndex: individualSellerWorkingAsChoices.indexOf(workingAs),
        dirty: false,
        placeholder: null,
    };
}

function privatPersonGroup(gardener: GardenerDocument, company: Company | null, personalInformation: PersonalInformationDocument | null): PrivatpersonGroup {
    return {
        type: "group",
        render: "all at once",
        items: [
            nameTextArea(gardener, company),
            addressAddressPicker(gardener),
            cprTextArea(personalInformation),
            cannotHandOverProfileNotice(),
            workingAsCompanyNotice(),
            reasonPage(gardener, personalInformation),
            confirmationPage("privatperson"),
        ],
    };
}

function personligtEjetMindreVirksomhedGroup(gardener: GardenerDocument, company: Company | null, personalInformation: PersonalInformationDocument | null): PersonligtEjetMindreVirksomhedGroup {
    return {
        type: "group",
        render: "all at once",
        items: [
            nameTextArea(gardener, company),
            addressAddressPicker(gardener),
            cprTextArea(personalInformation),
            cvrTextArea(gardener, "Personligt ejet mindre virksomhed"),
            cannotHandOverProfileNotice(),
            workingAsCompanyNotice(),
            reasonPage(gardener, personalInformation),
            confirmationPage("personligt ejet mindre virksomhed"),
        ],
    };
};

function enkeltmandsvirksomhedGroup(gardener: GardenerDocument, company: Company | null, personalInformation: PersonalInformationDocument | null): EnkeltmandsvirksomhedGroup {
    return {
        type: "group",
        render: "all at once",
        items: [
            nameTextArea(gardener, company),
            addressAddressPicker(gardener),
            cprTextArea(personalInformation),
            cvrTextArea(gardener, "Enkeltmandsvirksomhed"),
            cannotHandOverProfileNotice(),
            workingAsCompanyNotice(),
            reasonPage(gardener, personalInformation),
            confirmationPage("enkeltmandsvirksomhed"),
        ],
    };
};

function andenJuridiskEnhedGroup(gardener: GardenerDocument, company: Company | null): AndenJuridiskEnhedGroup {
    return {
        type: "group",
        render: "all at once",
        items: [
            nameTextArea(gardener, company),
            addressAddressPicker(gardener),
            cvrTextArea(gardener, "Anden juridisk enhed"),
            workingAsOtherEntityNotice(),
            confirmationPage("anden juridisk enhed"),
        ],
    };
};

function nameTextArea(gardener: GardenerDocument, company: Company | null): TextAreaFormItem {
    const workingAs = formatWorkingAs(company);

    return {
        id: UpdateGardenerFormIds.TextAreaName,
        type: "text-area",
        render: "text-field",
        label: workingAs !== "Anden juridisk enhed" ? "Navn" : "Kontaktperson",
        placeholder: null,
        value: gardener.name,
        dirty: false,
        disabled: workingAs === "Anden juridisk enhed",
        validation: "mandatory",
    };
}

function addressAddressPicker(gardener: GardenerDocument): AddressPickerFormItem {
    return {
        id: UpdateGardenerFormIds.AddressPickerAddress,
        type: "address-picker",
        label: "Adresse",

        searchTerm: `${gardener.address.street}, ${gardener.address.zipCode}`,
        value: null,
        dirty: false,
        validation: "mandatory",
    };
}

function cprTextArea(personalInformation: PersonalInformationDocument | null): TextAreaFormItem {
    return {
        id: UpdateGardenerFormIds.TextAreaCpr,
        type: "text-area",
        render: "text-field",
        label: "CPR-nummer",
        placeholder: null,
        value: personalInformation?.cpr ?? "",
        dirty: personalInformation?.cpr ? false : true,
        validation: "cpr",
    };
}

function cvrTextArea(gardener: GardenerDocument, workingAs: Exclude<WorkingAs, "Privatperson">): TextAreaFormItem {
    const disabled = workingAs === "Anden juridisk enhed";
    const value = disabled ? gardener.cvr ?? "Ikke angivet" : gardener.cvr ?? "";

    return {
        id: UpdateGardenerFormIds.TextAreaCvr,
        type: "text-area",
        render: "text-field",
        label: "CVR-nummer",
        placeholder: null,
        value,
        dirty: gardener.cvr ? false : true,
        disabled: workingAs === "Anden juridisk enhed",
        validation: "cvr",
    };
}

function cannotHandOverProfileNotice(): NoticeFormItem {
    return {
        type: "notice",
        render: "warning",
        html: `
            <strong>OBS:</strong> Du kan ikke overdrage din profil til en anden person. De kan i stedet sende en ansøgning.
        `,
    }
}

function workingAsCompanyNotice(): NoticeFormItem {
    return {
        type: "notice",
        render: "warning",
        html: `
            <strong>OBS:</strong> Hvis du vil arbejde som et selskab, kan du sende en ny ansøgning.<br />
            <br />
            Kontakt os for at høre nærmere, hvis det er relevant for dig.
        `,
    }
};

function workingAsOtherEntityNotice(): NoticeFormItem {
    return {
        type: "notice",
        render: "warning",
        html: `
            <strong>OBS:</strong> Hvis du vil arbejde som privatperson eller en anden virksomhed, kan du sende en ny ansøgning.<br />
            <br />
            Kontakt os for at høre nærmere, hvis det er relevant for dig.
        `,
    }
}

function reasonPage(gardener: GardenerDocument, personalInformation: PersonalInformationDocument | null): UpdateGardenerReasonsPageFormItem {
    return {
        id: UpdateGardenerFormIds.PageReasons,
        type: "update-gardener-reasons-page",
        title: "Ret stamdata",

        nameTextAreaId: UpdateGardenerFormIds.TextAreaName,
        initialName: gardener.name,

        cprTextAreaId: UpdateGardenerFormIds.TextAreaCpr,
        initialCpr: personalInformation?.cpr ?? null,

        nameChangeReason: {
            id: UpdateGardenerFormIds.TextAreaNameChangeReason,
            type: "text-area",
            render: "text-area",
            label: "Årsag til ændring af navn",
            placeholder: "Hvad er årsagen til at du vil ændre dit navn?",
            value: "",
            dirty: false,
            validation: "mandatory",
        },
        cprChangeReason: {
            id: UpdateGardenerFormIds.TextAreaCprChangeReason,
            type: "text-area",
            render: "text-area",
            label: "Årsag til ændring af CPR-nummer",
            placeholder: "Hvad er årsagen til at du vil ændre dit CPR-nummer?",
            value: "",
            dirty: false,
            validation: "mandatory",
        },
    }
}

function confirmationPage(context: "privatperson" | "personligt ejet mindre virksomhed" | "enkeltmandsvirksomhed" | "anden juridisk enhed"): ConfirmationPage {
    const lines: string[] = [];

    if ( context !== "anden juridisk enhed" ) {
        lines.push("Vi modtager dine oplysninger med det samme. Er der behov for manuel gennemgang, kan der gå et par dage inden rettelsen træder i kraft. I så fald kan du følge status på din profil.");
        if ( context === "privatperson" ) lines.push("Når du arbejder som privatperson, vil dit CPR-nummer bliver påført dine fakturaer.");
        if ( context === "personligt ejet mindre virksomhed" ) lines.push("Når du arbejder som personligt ejet mindre virksomhed, vil dit CVR-nummer bliver påført dine fakturaer.");
        if ( context === "enkeltmandsvirksomhed" ) lines.push("Når du arbejder som enkeltmandsvirksomhed, vil dit CVR-nummer bliver påført dine fakturaer.");
    } else {
        lines.push("Din rettelse træder i kraft med det samme.");
    }

    return {
        type: "page",
        title: "Ret stamdata",
        item: {
            render: "info",
            type: "info",
            text: lines.join("<br /><br />"),
        },
    }
}
