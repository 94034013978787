import { useCallback, useMemo } from "react";

import { Collapse } from "@material-ui/core";

import { AlternativeHint, Group, Label, TextArea, TextField } from "../../../form";
import { Validation, validateBankAccountNumberValidation, validateBankRegistrationNumberValidation, validateCprValidation, validateCvrValidation, validateEmailValidation, validateMandatoryValidation, validateStrictPhoneNumberValidation } from "../../../validation/validation";
import { useForm } from "../../framework/react/FormProvider";
import { FormItemSupport } from "../../framework/core/form-item.support";

export interface TextAreaFormItem {
    id: string;
    type: "text-area";
    render?: "text-area" | "text-field";
    label: string | null;
    placeholder: string | null;
    value: string;
    dirty: boolean;
    disabled?: boolean;
    validation?: "optional" | "mandatory" | "phone" | "email" | "bank registration number" | "bank account number" | "cpr" | "cvr";
}

interface TextAreaFormItemComponentProps {
    item: TextAreaFormItem;
}

export const TextAreaFormItemComponent = (props: TextAreaFormItemComponentProps) => {
    const { controller, validationAnimationDuration, updateItem } = useForm();
    const { item } = props;

    const updateValue = useCallback((value: string) => {
        updateItem(item.id, (state: TextAreaFormItem) => ({ ...state, value }));
    }, [updateItem, item.id]);

    const markDirty = useCallback(() => {
        updateItem(item.id, state => controller.markDirty(state));
    }, [controller, updateItem, item.id]);

    const validation = useMemo(() => validate(item), [item]);
    const render = item.render ?? "text-area";
    const disabled = item.disabled ?? false;

    return (
        <div id={`${item.id}-section`}>
            {item.label ? <Label htmlFor={item.id} style={{ marginBottom: "8px" }}>{item.label}</Label> : null}
            <Group error={Boolean(item.dirty && validation?.severity === "error")}>
                {(() => {
                    switch ( render ) {
                        case "text-field": return <TextField id={item.id} value={item.value} onChange={updateValue} onBlur={markDirty} placeholder={item.placeholder ?? undefined} disabled={disabled} />; 
                        case "text-area": return <TextArea id={item.id} value={item.value} onChange={updateValue} onBlur={markDirty} placeholder={item.placeholder ?? undefined} disabled={disabled} />;
                    }
                })()}

                <Collapse in={Boolean(item.dirty && validation)} timeout={validationAnimationDuration}>
                    <AlternativeHint severity={validation?.severity} message={validation?.message} />
                </Collapse>
            </Group>
        </div>
    );
};

export class TextAreaFormItemSupport implements FormItemSupport<TextAreaFormItem> {
    supportedType = "text-area" as const;
    updateChildren = undefined;

    markDirty(item: TextAreaFormItem): TextAreaFormItem {
        return { ...item, dirty: true };
    }

    getAllChildren() {
        return [];
    }

    getActiveChildren() {
        return [];
    }

    blocksParent(item: TextAreaFormItem): boolean {
        return validate(item)?.severity === "error";
    }
}

const validate = (item: TextAreaFormItem): Validation => {
    const validation = item.validation ?? "mandatory";

    const validators: Validation[] = (() => {
        switch ( validation ) {
            case "optional": return [];
            case "mandatory": return [validateMandatoryValidation(item.value)];
            case "email": return [validateMandatoryValidation(item.value), validateEmailValidation(item.value)];
            case "phone": return [validateMandatoryValidation(item.value), validateStrictPhoneNumberValidation(item.value)];
            case "bank registration number": return [validateMandatoryValidation(item.value), validateBankRegistrationNumberValidation(item.value)];
            case "bank account number": return [validateMandatoryValidation(item.value), validateBankAccountNumberValidation(item.value)];
            case "cpr": return [validateMandatoryValidation(item.value), validateCprValidation(item.value)];
            case "cvr": return [validateMandatoryValidation(item.value), validateCvrValidation(item.value)];
        }
    })();

    const error = validators.find(x => x?.severity === "error");
    if ( error ) return error;

    const warning = validators.find(x => x?.severity === "warning");
    if ( warning ) return warning;

    return undefined;
}
