import { useCallback } from "react";

import firebase from "firebase/app";

import { useBlockingProgress } from "../../components/blocking-progress";
import { useSnackbar } from "../../components/snackbar";
import { CreateRegistrationDialogProps } from "./CreateRegistrationDialog";
import { Task } from "../registration.model";

interface CreateRegistrationWithoutAppointmentData {
    customerId: string;
    serviceDate: string;
    tasks: Task[];
}

const createRegistrationWithoutAppointmentFunction: (data: CreateRegistrationWithoutAppointmentData) => Promise<firebase.functions.HttpsCallableResult>
    = firebase.app().functions("europe-west3").httpsCallable("Registration-createWithoutAppointment");

export const useCreateRegistrationWithoutAppointmentAction = () => {
    const { block, unblock } = useBlockingProgress();
    const { openSnackbar } = useSnackbar();

    const action: CreateRegistrationDialogProps["onConfirm"] = useCallback((viewModel) => {
        block();

        const data: CreateRegistrationWithoutAppointmentData = {
            customerId: viewModel.customer.id,
            serviceDate: viewModel.appointmentDate,
            tasks: viewModel.tasks.map(task => ({
                description: task.description,
                durationInMinutes: task.duration!,
                tools: task.tools!,
            })),
        };

        createRegistrationWithoutAppointmentFunction(data)
            .then(response => {
                if ( response.data === "has-already-scheduled-appointment" ) {
                    openSnackbar("warning", "Du har allerede aftalt et besøg med kunden på denne dato");
                } else {
                    openSnackbar("success", "Vi har modtaget din registrering, som nu kan findes under \"Tidsregistreringer\".");
                }
                unblock();
            })
            .catch(() => {
                openSnackbar("error", "Der skete en fejl. Din registrering blev ikke gennemført.");
                unblock();
            });

    }, [block, unblock, openSnackbar]);

    return action;
};
