import { useCallback } from "react";

import firebase from "firebase/app";

import { useBlockingProgress } from "../components/blocking-progress";
import { useSnackbar } from "../components/snackbar";

export type NewTaskResponseData = AcceptTaskData | DeclineTaskData;

interface AcceptTaskData {
    action: "accept";
    gardeningTaskId: string;
}

interface DeclineTaskData {
    action: "decline";
    gardeningTaskId: string;
    reasons: DeclineTaskReason[];
    comment: string | null;
}

export type DeclineTaskReason = "do-not-understand" | "do-not-have-time" | "cannot-perform" | "too-far-away-or-too-small" | "other";

const newTaskResponse: (data: NewTaskResponseData) => Promise<firebase.functions.HttpsCallableResult>
    = firebase.app().functions("europe-west3").httpsCallable("Match-newTaskResponse");

export const useNewTaskResponseAction = (callback?: (action: NewTaskResponseData["action"]) => void) => {
    const { block, unblock } = useBlockingProgress();
    const { openSnackbar } = useSnackbar();

    return useCallback((data: NewTaskResponseData) => {
        block();

        newTaskResponse(data)
            .then(() => {
                openSnackbar("success", "Besvarelsen er modtaget");
                unblock();
                if ( callback ) callback(data.action);
            })
            .catch(() => {
                openSnackbar("error", "Der skete en fejl. Besvarelsen er ikke blevet modtaget.");
                unblock();
            });

    }, [block, unblock, openSnackbar, callback]);
};
