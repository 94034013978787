import { FormItem } from "../../framework/core/form-item.model";
import { FormItemSupport } from "../../framework/core/form-item.support";
import { PageFormItem } from "./PageFormItemComponent";
import { GroupFormItem } from "./GroupFormItemComponent";
import { FormController } from "../../framework/core/form.controller";
import { TextAreaFormItem } from "./TextAreaFormItemComponent";

export interface UpdateGardenerReasonsPageFormItem {
    id: string;
    type: "update-gardener-reasons-page",
    title: string;

    nameTextAreaId: string;
    initialName: string;

    cprTextAreaId: string;
    initialCpr: string | null;

    nameChangeReason: TextAreaFormItem,
    cprChangeReason: TextAreaFormItem,
}

interface UpdateGardenerReasonsFormItemComponentProps {
    item: UpdateGardenerReasonsPageFormItem;
}

export const UpdateGardenerReasonsFormItemComponent = (_props: UpdateGardenerReasonsFormItemComponentProps) => {
    return null;
};

export class UpdateGardenerReasonsFormItemSupport implements FormItemSupport<UpdateGardenerReasonsPageFormItem> {
    supportedType = "update-gardener-reasons-page" as const;

    updateChildren = (item: UpdateGardenerReasonsPageFormItem, updateChildItem: (item: FormItem) => FormItem): UpdateGardenerReasonsPageFormItem => {
        return {
            ...item,
            nameChangeReason: updateChildItem(item.nameChangeReason) as typeof item.nameChangeReason,
            cprChangeReason: updateChildItem(item.cprChangeReason) as typeof item.cprChangeReason,
        };
    };

    markDirty(item: UpdateGardenerReasonsPageFormItem): UpdateGardenerReasonsPageFormItem {
        return {
            ...item,
            nameChangeReason: {
                ...item.nameChangeReason,
                dirty: true,
            },
            cprChangeReason: {
                ...item.cprChangeReason,
                dirty: true,
            },
        };
    }

    getAllChildren(_controller: FormController, item: UpdateGardenerReasonsPageFormItem) {
        const page = createPage(item, [item.nameChangeReason, item.cprChangeReason]);

        return [page];
    }

    getActiveChildren(controller: FormController, item: UpdateGardenerReasonsPageFormItem, rootItem: FormItem) {
        const currentName = (controller.getItem(item.nameTextAreaId, rootItem) as TextAreaFormItem).value.trim();
        const nameChanged = currentName.toLowerCase() !== item.initialName.toLowerCase();

        const currentCpr = (controller.getItem(item.cprTextAreaId, rootItem) as TextAreaFormItem).value;
        const cprChanged = item.initialCpr ? currentCpr !== item.initialCpr : false;

        const items: TextAreaFormItem[] = [];
        if ( nameChanged ) items.push(item.nameChangeReason);
        if ( cprChanged ) items.push(item.cprChangeReason);

        if ( items.length === 0 ) return [];

        const page = createPage(item, items);

        return [page];
    }

    blocksParent(_item: UpdateGardenerReasonsPageFormItem): boolean {
        return false;
    }
}

const createPage = (item: UpdateGardenerReasonsPageFormItem, items: TextAreaFormItem[]): PageFormItem<GroupFormItem<TextAreaFormItem[]>> => {
    return {
        type: "page",
        title: item.title,
        item: {
            type: "group",
            render: "all at once",
            items,
        },
    };
}
