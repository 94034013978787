import { useCompanyByCvr } from "../company/company.hook";
import { usePauseGardenerSwitch } from "../gardener/GardenerPausedSwitch";
import { useGardenerBalance } from "../gardener/payout/gardener-balance.hook";
import { useGardenerPayout } from "../gardener/payout/gardener-payout.hook";
import { useRequestPayoutAction } from "../gardener/payout/request-payout.hook";
import { usePersonalInformation } from "../gardener/personal-information.hook";
import { useUpdateTaskAgent } from "../gardener/PreferencesCard";
import { ProfilePage } from "../gardener/ProfilePage";
import { useTaskResponseAction } from "../task/task-response-action.hook";
import { useUser } from "../user/UserProvider";
import { ErrorPage } from "./ErrorPage";
import { LinearProgress } from "./LinearProgress";

export const IntegratedProfilePage = () => {
    const gardener = useUser();
    const company = useCompanyByCvr(gardener.cvr);
    const taskResponseAction = useTaskResponseAction();
    const requestPayoutAction = useRequestPayoutAction();

    const gardenerBalance = useGardenerBalance(gardener.id);
    const gardenerPayout = useGardenerPayout(gardener.id);
    const personalInformation = usePersonalInformation(gardener.id);
    const { paused, toggleGardenerPaused } = usePauseGardenerSwitch(gardener);
    const updateTaskAgent = useUpdateTaskAgent();

    if ( gardener.cvr && company === null ) return <ErrorPage message={`Der skete en fejl. Kunne ikke opslå virksomhed. Brug "Bed om hjælp" eller kontakt udvikling@gogogarden.dk.`} />;
    // TODO: Should create gardener balance synchronously when creating gardener and then lead to an error page if it is not found
    // TODO: Should create personal information synchrnously when creating gardener and then lead to an error page if it is not found
    if ( company === undefined || gardenerBalance === undefined || gardenerPayout === undefined || personalInformation === undefined ) return <LinearProgress />;

    return (
        <ProfilePage
            gardener={gardener}
            company={company && company.data}
            gardenerBalance={gardenerBalance}
            gardenerPayout={gardenerPayout}
            personalInformation={personalInformation}
            taskResponseAction={taskResponseAction}
            requestPayoutAction={requestPayoutAction}
            gardenerPaused={paused}
            toggleGardenerPaused={toggleGardenerPaused}
            updateTaskAgent={updateTaskAgent}
        />
    );
};
