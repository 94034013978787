import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useRouteMatch } from "react-router";

import { Fade, makeStyles } from "@material-ui/core";

import { LightAndGrayButton } from "../components/button";
import { useUser } from "../user/UserProvider";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        padding: "24px",

        "& > *:first-child": {
            marginRight: "48px",
        },

        [theme.breakpoints.down(1200)]: {
            flexDirection: "column",
            textAlign: "center",

            "& > *:first-child": {
                marginRight: 0,
                marginBottom: "16px",
            },

            "& > *:first-child:last-child": {
                marginBottom: 0,
            },
        },
    },
    notice: {
        backgroundColor: "#A259FF",
    },
    warning: {
        backgroundColor: "#EC8000",
    },
    title: {
        fontSize: "16px",
        fontWeight: 600,
        color: "#FFFFFF",
        margin: 0,
        marginBottom: "4px",
    },
    message: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#FFFFFF",
        margin: 0,
    },
    buttonWrapper: {
        flexShrink: 0,
    },
}));

interface Dac7BannerProps {
    enabled: boolean
}

export const Dac7Banner = (props: Dac7BannerProps) => {
    const classes = useStyles();
    const { enabled } = props;

    const gardener = useUser();

    const history = useHistory();
    const goToProfile = useCallback(() => {
        history.push("/profile");
    }, [history]);

    const scenario = useMemo(() => {
        if ( !enabled ) return null;

        if ( gardener.dac7.commissionWithheld ) return "sanction initiated";

        return "request initiated";
    }, [enabled, gardener.dac7.commissionWithheld]);

    const [retainedScenario, setRetainedScenario] = useState<typeof scenario>(scenario);
    useEffect(() => {
        if ( !scenario ) return;
        if ( scenario === retainedScenario ) return;

        setRetainedScenario(scenario);
    }, [scenario, retainedScenario]);

    const severityClass = useMemo(() => {
        if ( !retainedScenario ) return null;

        switch ( retainedScenario ) {
            case "request initiated": return classes.notice;
            case "sanction initiated": return classes.warning;
        }
    }, [retainedScenario, classes]);

    const message = useMemo(() => {
        if ( !retainedScenario ) return null;

        switch ( retainedScenario ) {
            case "request initiated":
                return (
                    <div>
                        <h4 className={classes.title}>Indsend dine stamdata igen</h4>
                        <p className={classes.message}>
                            Ifølge DAC7-lovgivningen skal vi anmode dig om, at indsende dine stamdata igen.<br />
                            <br />
                            Du kan indsende dine stamdata ved at rette dem på din profil. Hvis dine stamdata allerede er korrekte, skal du stadig indsende dem igen.
                        </p>
                    </div>
                );

            case "sanction initiated":
                return (
                    <div>
                        <h4 className={classes.title}>Indsend dine stamdata igen</h4>
                        <p className={classes.message}>
                            Ifølge DAC7-lovgivningen skal vi sanktionere dig, da du ikke har indsendt dine stamdata endnu. Vi tilbageholder derfor dit honorar, indtil du har indsendt dine stamdata igen.<br />
                            <br />
                            Du kan indsende dine stamdata ved at rette dem på din profil. Hvis dine stamdata allerede er korrekte, skal du stadig indsende dem igen.
                        </p>
                    </div>
                );
        }
    }, [retainedScenario, classes]);

    const route = useRouteMatch();
    const shouldShowProfilebutton = (retainedScenario === "request initiated" || retainedScenario === "sanction initiated") && route.url !== "/profile";

    const button = useMemo(() => {
        if ( shouldShowProfilebutton ) return <LightAndGrayButton onClick={goToProfile}>Gå til din profil</LightAndGrayButton>;

        return null;
    }, [goToProfile, shouldShowProfilebutton]);

    if ( !retainedScenario ) return null;

    return (
        <div className={`${classes.root} ${severityClass}`}>

            {message}

            <Fade appear={false} in={shouldShowProfilebutton} unmountOnExit>
                <div className={classes.buttonWrapper}>
                    {button}
                </div>
            </Fade>

        </div>
    );
};
