import { GardenerDocument } from "../gardener/gardener.model";
import { Company } from "./company.model"

export type WorkingAs = "Privatperson" | "Personligt ejet mindre virksomhed" | "Enkeltmandsvirksomhed" | "Anden juridisk enhed";

export const formatWorkingAs = (company: Company | null): WorkingAs => {
    if ( !company ) return "Privatperson";

    if ( company.type === "personligt ejet mindre virksomhed" ) return "Personligt ejet mindre virksomhed";
    if ( company.type === "enkeltmandsvirksomhed" ) return "Enkeltmandsvirksomhed";

    return "Anden juridisk enhed";
}

export const formatPaysVat = (gardener: GardenerDocument) => {
    if ( !gardener.cvr || !gardener.paysVat ) return "ikke momsregistreret";

    return "momsregistreret";
};
