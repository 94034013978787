import { IconButton, makeStyles } from "@material-ui/core";
import EditIcon from "@material-ui/icons/EditOutlined";

import { CardContent } from "../components/card/CardContent";
import { GardenerDocument } from "./gardener.model";
import { Card, CardHeader } from "../components/card";
import { TaskResponseData } from "../task/task-response-action.hook";
import { useCallback } from "react";
import { FormProps } from "../components/form-item/framework/react/FormProvider";
import { FormItem } from "../components/form-item/framework/core/form-item.model";
import { TextAreaFormItem } from "../components/form-item/client/components";
import { UpdateContactInformationFormIds, useUpdateContactInformationDialog } from "./UpdateContactInformationDialog";

const useStyles = makeStyles({
    group: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "24px",

        "&:last-child": {
            marginBottom: 0,
        },

        "& > label": {
            fontSize: "14px",
            fontWeight: 500,
            color: "#1C5B13",
            marginBottom: "4px",
        },

        "& > span": {
            fontSize: "16px",
            fontWeight: 400,
            color: "#4A4A4A",
        }
    },
    editIcon: {
        fontSize: "24px",
        color: "#297A1D",
    },
});

interface ContactInformationCardProps {
    gardener: GardenerDocument;
    taskResponseAction: (data: TaskResponseData | string) => void;
}

export const ContactInformationCard = (props: ContactInformationCardProps) => {
    const classes = useStyles();

    const { gardener, taskResponseAction } = props;

    const updateContactInformationResponseAdapter = useCallback<FormProps<FormItem>["onSubmit"]>((controller, form): void => {
        const phoneNumber = (controller.getItem(UpdateContactInformationFormIds.TextAreaPhoneNumber, form) as TextAreaFormItem).value;
        const email = (controller.getItem(UpdateContactInformationFormIds.TextAreaEmail, form) as TextAreaFormItem).value;

        taskResponseAction({
            action: "update contact information",
            email,
            phoneNumber,
        });
    }, [taskResponseAction]);
    const openUpdateGardenerDialog = useUpdateContactInformationDialog(gardener, updateContactInformationResponseAdapter);

    const editButton = (
        <IconButton aria-label="edit" onClick={openUpdateGardenerDialog}>
            <EditIcon className={classes.editIcon} />
        </IconButton>
    );

    return (
        <Card>
            <CardHeader title="Kontaktoplysninger" button={editButton} />

            <CardContent>

                <div className={classes.group}>
                    <label>Email</label>
                    <span>{gardener.email}</span>
                </div>

                <div className={classes.group}>
                    <label>Telefon</label>
                    <span>{gardener.phoneNumber}</span>
                </div>

            </CardContent>
        </Card>
    );
};
