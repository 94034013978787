import { useCallback, useEffect, useState } from "react";

import firebase from "firebase/app";

import { GardenerDocument } from "./gardener.model";
import { useSnackbar } from "../components/snackbar";
import { Switch, OnButton, OffButton } from "../components/switch";
import { useBlockingProgress } from "../components/blocking-progress";
import { useTranslation } from "../internationalisation/translation.hook";

const activateManualGardenerProtection = firebase.app().functions("europe-west3").httpsCallable("ManualGardenerProtection-activate");
const deactivateManualGardenerProtection = firebase.app().functions("europe-west3").httpsCallable("ManualGardenerProtection-deactivate");

export const usePauseGardenerSwitch = (gardener: GardenerDocument) => {
    const expectedPaused = gardener.status === "terminated" || gardener.protections.includes("manual-gardener") || gardener.protections.includes("no-reply") || gardener.protections.includes("picky-gardener") || gardener.protections.includes("process");

    const { block, unblock } = useBlockingProgress();
    const { openSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const [paused, setPaused] = useState(expectedPaused);

    const gardenerId = gardener.id;

    const toggleGardenerPaused = useCallback(() => {
        block();
        setPaused(!paused);

        const toggleProtection = paused ? deactivateManualGardenerProtection : activateManualGardenerProtection;
        toggleProtection({ gardenerId })
            .catch(() => {
                setPaused(paused);
                openSnackbar("error", t("GardenerPausedSwitch: toggle-error"))
            });

    }, [block, openSnackbar, t, paused, gardenerId]);

    useEffect(() => {
        setPaused(expectedPaused);
        unblock();
    }, [expectedPaused, unblock]);

    return { paused, toggleGardenerPaused };
};

interface GardenerPausedSwitchProps {
    gardener: GardenerDocument;
    gardenerPaused: boolean;
    toggleGardenerPaused: () => void;
}

export const GardenerPausedSwitch = (props: GardenerPausedSwitchProps) => {
    const { gardener, gardenerPaused, toggleGardenerPaused } = props;
    const { t } = useTranslation();

    const disabled = gardener.status === "terminated" || gardener.protections.includes("process");
    const toggleGardenerPausedIfNotDisabled = useCallback(() => {
        if ( disabled ) return;

        toggleGardenerPaused();
    }, [disabled, toggleGardenerPaused]);

    return (
        <Switch disabled={disabled}>
            <OnButton active={!gardenerPaused} onClick={toggleGardenerPausedIfNotDisabled}>{t("GardenerPausedSwitch: active-button")}</OnButton>
            <OffButton active={gardenerPaused} onClick={toggleGardenerPausedIfNotDisabled}>{t("GardenerPausedSwitch: paused-button")}</OffButton>
        </Switch>
    );
};
