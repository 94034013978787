import { useEffect, useState } from "react";

import firebase from "firebase/app";

import { CompanyDocument } from "./company.model";

export const useCompanyByCvr = (cvr: string | null | undefined): CompanyDocument | null | undefined => {
    const [state, setState] = useState<CompanyDocument | null>();

    useEffect(() => {
        if ( cvr === null ) return setState(null);
        if ( cvr === undefined ) return setState(undefined);

        setState(undefined);

        return firebase.firestore().collection("companies").where("data.cvr", "==", cvr).onSnapshot(snapshot => {
            if ( snapshot.docs.length === 0 ) return setState(null);
            
            const document = snapshot.docs[0];

            setState({ id: document.id, ...document.data() } as CompanyDocument);
        });
    }, [cvr]);

    return state;
};
