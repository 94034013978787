import { Container, makeStyles } from "@material-ui/core";

import { ContentSection } from "../application/ContentSection";
import { TitleHeader } from "../components/page/TitleHeader";
import { GardenerDocument, TaskAgent } from "./gardener.model";
import { PreferencesCard } from "./PreferencesCard";
import { GardenerPausedSwitch } from "./GardenerPausedSwitch";
import { GardenerCard } from "./GardenerCard";
import { TaskResponseData } from "../task/task-response-action.hook";
import { CommissionPayoutCard } from "./payout/CommissionPayoutCard";
import { GardenerBalanceDocument } from "./payout/gardener-balance.model";
import { GardenerPayoutDocument } from "./payout/gardener-payout.model";
import { PersonalInformationDocument } from "./personal-information.model";
import { RequestGardenerPayoutData } from "./payout/request-payout.hook";
import { ContactInformationCard } from "./ContactInformationCard";
import { Company } from "../company/company.model";

const useStyles = makeStyles({
    container: {
        "& > *": {
            marginBottom: "32px",
        },
    
        "& > *:last-child": {
            marginBottom: 0,
        },
    },
});

export interface ProfilePageProps {
    gardener: GardenerDocument;
    company: Company | null;
    gardenerBalance: GardenerBalanceDocument | null;
    gardenerPayout: GardenerPayoutDocument | null;
    personalInformation: PersonalInformationDocument | null;
    taskResponseAction: (data: TaskResponseData | string) => void;
    requestPayoutAction: (data: RequestGardenerPayoutData) => void;
    gardenerPaused: boolean;
    toggleGardenerPaused: () => void;
    updateTaskAgent: (gardenerId: string, taskAgent: Partial<TaskAgent>, onSuccess: () => void) => void;
}

export const ProfilePage = (props: ProfilePageProps) => {
    const classes = useStyles();

    const { gardener, company, gardenerBalance, gardenerPayout, personalInformation, taskResponseAction, requestPayoutAction, gardenerPaused, toggleGardenerPaused, updateTaskAgent } = props;

    return (
        <>
            <TitleHeader>Profil</TitleHeader>

            <ContentSection>
                <Container maxWidth="sm" disableGutters className={classes.container}>

                    <GardenerPausedSwitch gardener={gardener} gardenerPaused={gardenerPaused} toggleGardenerPaused={toggleGardenerPaused} />
                    <GardenerCard gardener={gardener} company={company} personalInformation={personalInformation} taskResponseAction={taskResponseAction} />
                    <ContactInformationCard gardener={gardener} taskResponseAction={taskResponseAction} />

                    {gardenerBalance && personalInformation ? (
                        <CommissionPayoutCard
                            payoutInProgress={gardenerBalance.inProgress}
                            amountScheduled={gardenerPayout?.amount ?? null}
                            balance={gardenerBalance.balance}
                            dac7={gardener.dac7}
                            bankAccount={personalInformation.bankAccount}
                            onRequestPayout={requestPayoutAction}
                        />
                    ) : null}

                    <PreferencesCard gardener={gardener} updateTaskAgent={updateTaskAgent} />

                </Container>
            </ContentSection>
        </>
    )
};
